import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { MdDone } from "react-icons/md";
// import { FaShieldAlt, FaUsers } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getUserCompanyInfo, getUserInfo, isLoggedIn, loginSuccess } from "../../redux/Slices/authSlice";
import { BASE_URL } from "../../util/Api";

const Welcome = () => {
  // const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const navigate  = useNavigate();
  const dispatch = useDispatch()



  const fetchData = async (token) => {
    try {
      if (token) {
        const response = await fetch(`${BASE_URL}/auth/users/me/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        });

        const result = await response.json();
        if (response.ok) {
          dispatch(isLoggedIn());
          dispatch(getUserInfo(result));

          // Fetch user profile photo
          const userProfileResponse = await fetch(
            `${BASE_URL}/properties/account/me`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );

          const userProfileData = await userProfileResponse.json();
          if (userProfileResponse.ok) {
            dispatch(getUserCompanyInfo(userProfileData));
            // setUserCompanyData(userProfileData);
          } else {
            console.log(
              "Error fetching user profile photo:",
              userProfileData
            );
          }
        } else {
          console.log("Error during login:", result);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/auth/jwt/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username:"Akhil", password:"Sunday@4321" }),
      });
      const result = await response.json();
      // setIsLoading(false)
      if (response.ok) {
        dispatch(loginSuccess(result));
        fetchData(result.access)
        // localStorage.setItem("token", result.access);
        navigate("/")
      } else {
        console.log("Error during login :", result);
        // setErrMessage(result);
      }
    } catch (error) {
      console.error("Error during logged In  ");
      // setIsLoading(false)
    }
  };
  // useEffect(() => {
  //   if (isAuthenticated === true || isAuthenticated === "true") {
  //     navigate("/");
  //   }
  // }, [isAuthenticated, navigate]);
  return (
    <div className="-mb-6 border-b-[1px] border-black">
      <div className="px-4 py-2  md:px-10  bg-gray-5 flex items-center justify-between bg-[#1E9DE4] text-white fixed w-full top-0 z-20">
        <Link to="/" className="flex items-center gap-1">
          <img
            src="/images/appLogo.png"
            alt=""
            className=" h-10 w-10 md:w-14 md:h-14 scale-150"
          />
          <span className="md:ml-5 ml-2 font-medium md:text-2xl text-xl">
            RealtorApp.io
          </span>
        </Link>

        <div className="flex gap-5">
          <a
            href="tel:+916356424934"
            className="md:hidden flex items-center justify-center p-2 rounded-md  text-white"
          >
            <IoCall className="w-6 h-6" />
          </a>
          <p className="hidden md:flex items-center gap-3  p-2 rounded-xl text-white">
            <span>
              <IoCall className="w-6 h-6" />
            </span>
            <span className="text-lg font-semibold">+91 9327055500</span>
          </p>
          <Link to="/login"  className="font-medium uppercase p-2 md:px-3 border-2 border-white hover:text-black  hover:bg-white  md:rounded-xl rounded-sm  transition-all">
            Log In
          </Link>
        </div>
      </div>
      <div className="md:pt-[150px] pt-[80px]   bg-[#e3f9fa] ">
        <div className="flex w-full md:pl-20 px-5 md:gap-20 justify-center flex-col md:flex-row">
          <div className="md:w-[40%] flex border items-center flex-col md:gap-10 gap-5 md:mb-20 mb-10 md:p-5 p-3 rounded-2xl bg-gradient-to-b from-teal-300 to-blue-500">
            <div className="">
              <h1 className="text-center md:text-3xl text-xl font-bold">
                Close More Deals with easy to use RealtorApp.io
              </h1>
            </div>
            <div className="flex  justify-center w-full px-8">
              {/* <div className="flex">
                <span className="flex justify-center items-center bg-white border rounded-s-xl border-e-4 px-2 font-semibold">
                  +91
                </span>
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter your phone number "
                  className="w-60 border p-2 rounded-e-xl bg-gradient-to-r  from-[#ffffff] to-[#00fff7]"
                />
              </div> */}
              <Link to="/register"  className="bg-gradient-to-b from-teal-300 to-blue-500  p-2 px-5 border rounded-xl text-white font-semibold">
                Get started
              </Link>
            </div>
            <div className="border rounded-xl p-2 bg-gradient-to-r md:mx-4 from-[#fcffff] to-[#bdfffd]">
              <h2 className="md:text-lg text-sm text-center font-bold pb-2">
                Growth Engine For Your Bussiness
              </h2>
              <div className="flex  justify-between items-center ">
                <p className="w-[40%] text-sm text-center border-e-4">
                  24x7 Access Anywhere, Anytime
                </p>
                <p className="w-[10%] text-sm text-center ">Data Safety</p>
                <p className="w-[40%] text-sm text-center border-s-4">
                  Get 3x Work done From each team member
                </p>
              </div>
            </div>
            <div className=" p-2 rounded-xl flex justify-center items-center bg-gradient-to-r md:mx-4 from-violet-500 to-orange-500">
              <p className="text-white text-sm">
                Try RealtorApp.io Desktop on a sample DEMO account.{" "}
                <span className="text-black font-bold">
                  No signup required.
                </span>
              </p>
              <button className="bg-gradient-to-b h-fit from-teal-300 to-blue-500 py-3 md:py-2  w-1/2 font-semibold text-white rounded-xl" onClick={handleSubmit}>
                View Demo
              </button>
            </div>
          </div>
          <div
            className="md:w-[60%] flex justify-center items-end   "
            style={{
              backgroundImage: "url('/images/circle.png')",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* <div>
                <img src="/images/circle.png" alt="" />
            </div> */}

            <div>
              <img src="/images/stockImg.png" alt="" className="w-full" />
            </div>
          </div>
        </div>
        <div
          className=" md:h-[700px]   relative bg-white"
          style={{
            backgroundImage: "url('/images/buildings.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex  justify-center md:gap-10 gap-5 flex-col md:flex-row  px-5  md:pb-10">
            {/* <div className="md:h-[150px] h-[100px] md:w-[35%]  rounded-xl -top-14 overflow-hidden relative flex justify-between items-center shadow-gray-400 shadow-md bg-[#b6ebed] border">
              <div className="w-28 h-28 bg-yellow-50 relative  -bottom-14 -left-10 rounded-full flex items-center justify-center text-white text-lg"></div>

              <img
                src="/images/appLogo.png"
                alt=""
                className="md:w-28 w-20 absolute md:left-3"
              />
              <div className="absolute md:right-10 right-10 z-10">
                <p className="text-md py-2 font-semibold">
                  Mobile App Coming Soon On
                </p>
                <div>
                  <img src="/images/g-a-store.png" alt="" className="md:h-10 h-8" />
                </div>
              </div>
              <div className="w-28 h-28 bg-yellow-50 relative  -top-14 right-10 rounded-full flex items-center justify-center text-white text-lg"></div>

             
            </div> */}

            {/* <div className="h-[150px] md:w-[35%]  rounded-xl -top-14 overflow-hidden hidden relative md:flex justify-between items-center shadow-gray-400 shadow-md bg-[#b6ebed] border">
              <div className="w-28 h-28 bg-red-50 relative  -bottom-14 left-10 rounded-full flex items-center justify-center text-white text-lg"></div>

              <img
                src="/images/appLogo.png"
                alt=""
                className="w-32 absolute  right-5 z-10"
              />
              <div className="absolute left-5">
                <p className="text-md py-2 font-semibold ">
                  No Training Required
                </p>
                <p className="font-semibold text-sm">
                  Easy to Use 
                </p>
              </div>
              <div className="w-28 h-28 bg-red-50 relative  -top-10 -right-10 rounded-full flex items-center justify-center text-white text-lg"></div>

            
            </div> */}
          </div>
          <div className="w-full  text-center md:pt-10 pb-20 ">
            <p className="text-red-600 text-base  pb-5 font-bold">
              POWERFUL FEATURES TO HELP YOU
            </p>
            <h1 className="md:text-7xl text-3xl font-bold ">
              <p>Built with features for</p> <p>growing businesses</p>
            </h1>
          </div>
        </div>
        <div className="md:px-32 px-5  relative   bg-white">
          <div className="flex flex-col relative md:-top-40 -top-10  gap-10 items-center ">
            
            <div className="bg-[#163269] shadow-black md:shadow-lg shadow-md text-white md:px-10 px-5 py-5 flex flex-col md:flex-row  rounded-xl w-full">
              <div className="flex flex-col md:gap-5 gap-3">
              <h1 className="md:text-2xl text-lg font-semibold">
                Manage Properties - Rent/Sale, Residential & Comercial
              </h1>
              <h2 className="md:text-xl text-base font-semibold">
                Create Property in less than 30 seconds
              </h2>
              <div className="flex flex-col gap-5 text-md font-semibold">
                <p className="flex items-center md:gap-5 gap-3">
                  <span>
                    <MdDone className="w-8 h-8 text-red-500" />
                  </span>
                  Custom fields like Area, Society, Source, Contactgroups etc.
                </p>
                <p className="flex items-center md:gap-5 gap-3">
                  <span>
                    <MdDone className="w-8 h-8 text-red-500" />
                  </span>
                  Store and share Images, Videos, Video Links, Brochures
                </p>
                <p className="flex items-center md:gap-5 gap-3">
                  <span>
                    <MdDone className="w-8 h-8 text-red-500" />
                  </span>
                  Fast Search Results.
                </p>
                
              </div>
              </div>
              <div><img src="/images/sale.png" alt="" /></div>
            </div>
            
            
            

            <div className="flex w-full  md:gap-10 flex-col md:flex-row gap-5 ">
              <div className="bg-[#12306c] shadow-black md:shadow-lg shadow-md text-white md:px-10 px-5 pt-5 flex flex-col  gap-5 rounded-xl w-full">
                <h1 className="text-2xl font-semibold">Inquiry Management</h1>
                {/* <h2>Track your stock in/out and profits</h2> */}
                <div className="flex flex-col gap-3 text-md font-semibold">
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>{" "}
                    Add inquiries directly from all major prooperty portal
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Assigning single and multiple inquiries to executives
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Add inquiry in less than 25 seconds
                  </p>
                  {/* <p className="flex items-center gap-5">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Profit tracking at daily, weekly and monthly level
                  </p> */}
                </div>
                <div className="flex justify-end md:-mt-10 -mt-20">
                <img src="/images/inquiryVector.png" alt="" className="md:w-[300px]  w-[150px] opacity-50 md:opacity-100"/>
              </div>
              </div>
              <div className=" bg-[#12306c] shadow-black  md:shadow-lg shadow-md text-white flex flex-col gap-5 md:px-10 px-5 pt-5 rounded-xl w-full">
                <h1 className="text-2xl font-semibold">
                  Manage Your Bussiness
                </h1>
                {/* <h2 className="text-xl font-semibold">Track your stock in/out and profits</h2> */}
                <div className="flex flex-col gap-2 text-md font-semibold">
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Check records who viewd porperty or inquiry
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Track inquiry status, sitevisits, tasks etc employee wise
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Prompt/Alerts for upcoming tasks, sitevisits
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Create or Add data in bulk
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 text-red-500" />
                    </span>
                    Secure your business data
                  </p>
                </div>
                <div className="flex justify-end -mt-28">
                <img src="/images/bussinessVector.png" alt=""  className="md:w-[300px]  w-[150px] opacity-50 md:opacity-70"/></div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:px-32 px-5  relative   bg-white">
          <div className="border-2 border-[#D1EFFA] shadow-black md:shadow-md relative md:-top-20   px-5 md:py-10 py-5 rounded-xl">
            <div className="flex md:gap-5 gap-2 pb-5 justify-center  items-center">
              <p className="border-t-4   md:w-[30%] w-[20%]"></p>
              <p className="md:text-base  font-bold text-gray-500 w-fit">
                AND THAT’S NOT ALL...
              </p>
              <p className="border-t-4  md:w-[30%] w-[20%]"></p>
            </div>
            <div className="md:grid grid-cols-3 flex flex-col gap-5 ">
              <div className="flex flex-col items-center text-center px-3">
                <img src="/images/6.png" alt="" />
                <h1 className="md:text-xl text-lg font-bold">
                  Manage Multiple Businesses
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Manage multiple businesses from the same account
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3">
                <img src="/images/7.png" alt="" />
                <h1 className="md:text-xl text-lg font-bold">Automatic Backup</h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Your data is automatically backed up when connected to
                  internet in a secured way.
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3">
                <img src="/images/8.png" alt="" />
                <h1 className="md:text-xl text-lg font-bold">GST & Business Reports</h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Get all GST reports & other insights to run your business
                  efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-32 pb-10 relative bg-white">
          <div className="border border-[#D1EFFA] bg-[#e3f9fa]  pl-10 py-5 rounded-xl grid grid-cols-2">
            <div>
              <img src="/images/logo.png" alt="" className="h-[200px] -ml-4" />
              <h1 className="text-4xl font-bold mt-5">
                <span className="text-blue-800">The #1</span> Tally * on mobile
                app
              </h1>
              <p className="text-xl font-semibold mt-5">
                Access your Tally * data and manage your business from your
                mobile anytime, anywhere
              </p>
              <button className="px-10 py-5 rounded-lg bg-white text-black text-lg mt-5">Learn More</button>
            </div>
            <div className="   flex  justify-end items-center">
              <div className="flex flex-col gap-5 px-10 rounded-l-2xl py-10 bg-gradient-to-b from-teal-300 to-blue-500 text-white ">
                <p className="flex items-center gap-2 text-lg font-semibold"><MdManageAccounts  className="h-8 w-8"/>Manage Properties & Inquiries </p>
                <p className="flex items-center gap-2 text-lg font-semibold"><FaUsers  className="h-8 w-8"/>Handle Subusers  </p>
                <p className="flex items-center gap-2 text-lg font-semibold"><FaShieldAlt  className="h-8 w-8"/> 100% Data Security </p>
              </div>
            </div>
            
              <p className="bg-yellow-400 p-2 font-semibold rounded-xl absolute -top-5   ">If you are using Realtorapp.io App, check this out!</p>
           
          </div>
        </div> */}
        <div className="md:px-32 px-5 md:pb-20 pb-5 pt-5 md:pt-0 bg-white ">
          <div className="border rounded-xl flex items-center pt-5 bg-[#e3f9fa]  justify-between px-5" 
          style={{
            backgroundImage: "url('/images/netBg.png')",
            // backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          >
            <div className="hidden w-[40%] md:flex flex-col gap-5 ">
              <h1 className="text-center text-3xl font-bold">
                Get started with RealtorApp.io
              </h1>
              <div className="flex  justify-center w-full ">
                {/* <div className="flex">
                  <span className="flex justify-center items-center bg-white border rounded-s-xl border-e-4 px-2 font-semibold">
                    +91
                  </span>
                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    placeholder="Enter your phone number "
                    className="w-60 border p-2 rounded-e-xl bg-gradient-to-r  from-[#ffffff] to-[#00fff7]"
                  />
                </div> */}
                <Link to="/register" className="bg-gradient-to-b from-teal-300 to-blue-500  p-2 px-5 border rounded-xl text-white font-semibold">
                  Get started
                </Link>
              </div>

              <div className="flex justify-between text-center items-center ">
                <p className="w-[40%] text-sm text-center border-e-4">
                  24x7 Access Anywhere, Anytime
                </p>
                <p className="w-[10%] text-sm text-center ">Data Safety</p>
                <p className="w-[40%] text-sm text-center border-s-4">
                  Get 3x Work done From each team member
                </p>
              </div>
            </div>
            <div >
              <img src="/images/mobileapp.png" alt=""  className="md:h-[400px] h-[200px]"/>
            </div>
            <div className="flex flex-col gap -5 items-center">
              {/* <img src="/images/Qr.png" alt="" className="h-32 w-32"/> */}
              <p className="md:text-xl text-base text-center">Mobile App Coming Soon On</p>
              <div className="px-5 flex flex-col gap-2">
              <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
                <img src="/images/android.png" alt=""  className="md:w-10 w-10"/>
                <div>
                  <p className="text-[10px]">Android app On</p>
                  <p className="md:text-lg md:font-semibold ">Google Play</p>
                </div>
              </div>
              <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
                <img src="/images/ios1.png" alt=""  className="w-10"/>
                <div>
                  <p className="text-[10px]">Download on the</p>
                  <p className="md:text-lg md:font-semibold">Apple store</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className=" p-2 rounded-xl flex  mt-2 justify-center items-center gap-2 bg-[#37DAD5]">
              <p className="text-white md:text-lg">
                Try RealtorApp.io Desktop on a sample DEMO account.{" "}
                <span className="text-black font-bold">
                  No signup required.
                </span>
              </p>
              <button className="bg-gradient-to-b  from-teal-300 to-blue-500 text-white px-5 py-2 font-bold  rounded-xl" onClick={handleSubmit}>
                View Demo
              </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
