// // src/redux/slices/namesSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import { BASE_URL } from '../../util/Api';


// const initialState = {
//   property_types: null,
//   property_subtypes: null,
//   deal_types: null,
//   bhk: null,
//   furnitures: null,
//   possessions: null,
//   areas: null,
//   cities:null,
//   societies: null,
//   contacts:null,
//   subusers:null,
//   loading: false,
//   error: null,
// }
// export const namesSlice = createSlice({
//   name: 'names',
//   initialState,
//   reducers: {
//     setLoading: (state, action) => {
//       state.loading = action.payload;
//     },
//     setError: (state, action) => {
//       state.error = action.payload;
//     },
//     getPropertyType: (state, action) => {
//       state.property_types = action.payload.results;
//     },
//     getSubType: (state, action) => {
//       state.property_subtypes = action.payload.results;
//     },
//     getDealType: (state, action) => {
//       state.deal_types = action.payload.results;
//     },
//     getbhk: (state, action) => {
//       state.bhk = action.payload.results;
//     },
//     getFurnitures: (state, action) => {
//       state.furnitures = action.payload.results;
//     },
//     getPossessions: (state, action) => {
//       state.possessions = action.payload.results;
//     },
//     getCities: (state, action) => {
//       state.cities = action.payload.results;
//     },
//     getAreas: (state, action) => {
//       state.areas = action.payload.results;
//     },
//     getSocieties: (state, action) => {
//       state.societies = action.payload.results;
//     },
//     getContacts: (state, action) => {
//       state.contacts = action.payload.results;
//     },
//     getSubusers: (state, action) => {
//       state.subusers = action.payload.results;
//     },
//     namesLogout: () => {
//       return initialState;
//     },
//   },
// });

// export const {
//   getDealType,
//   getFurnitures,
//   getPossessions,
//   getPropertyType,
//   getSubType,
//   getbhk,
//   getAreas,
//   getCities,
//   getSocieties,
//   getContacts,
//   getSubusers,
//   setLoading,
//   setError,

//   namesLogout
// } = namesSlice.actions;

// // Thunk to fetch all data except areas and societies
// export const fetchAllData = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));
//   const token = getState().auth.accessToken;

//   const endpoints = [
//     { url: `${BASE_URL}/properties/propertytype`, action: getPropertyType },
//     { url: `${BASE_URL}/properties/propertysubtype`, action: getSubType },
//     { url: `${BASE_URL}/properties/deal_type`, action: getDealType },
//     { url: `${BASE_URL}/properties/bhk`, action: getbhk },
//     { url: `${BASE_URL}/properties/furniture`, action: getFurnitures },
//     { url: `${BASE_URL}/properties/possession`, action: getPossessions },
//   ];

//   try {
//     // Fetch all data in parallel
//     const fetchPromises = endpoints.map((endpoint) =>
//       fetch(endpoint.url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `JWT ${token}`,
//         },
//       })
//         .then((response) => response.json())
//         .then((data) => dispatch(endpoint.action(data)))
//     );

//     await Promise.all(fetchPromises);
//   } catch (error) {
//     dispatch(setError(error.toString()));
//   } finally {
//     dispatch(setLoading(false));
//   }
// };

// // Thunk to fetch area data
// export const fetchCity = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));

//   try {
//     const fetchAllResults = async (url, accumulatedResults = []) => {
//         // Check if the URL is a full URL or a relative path
//         const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

//         const response = await fetch(fullUrl, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         });
//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }

//         // Accumulate results from this page
//         accumulatedResults = accumulatedResults.concat(data.results);

//         // If there's a next page, fetch the next page recursively
//         if (data.next) {
//             return fetchAllResults(data.next, accumulatedResults);
//         }

//         // Return the accumulated results along with other data fields
//         return {
//             count: data.count,
//             next: data.next,
//             previous: data.previous,
//             results: accumulatedResults,
//         };
//     };

//     const data = await fetchAllResults("/properties/city");
//     dispatch(getCities(data));
//     return data;
// } catch (error) {
//     console.error('API Error:', error);
//     dispatch(setError(error.toString()));
// } finally {
//     dispatch(setLoading(false));
//   }
// };
// // Thunk to fetch area data
// export const fetchAreas = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));
//   const token = getState().auth.accessToken;

//   try {
//     const fetchAllResults = async (url, accumulatedResults = []) => {
//         // Check if the URL is a full URL or a relative path
//         const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

//         const response = await fetch(fullUrl, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `JWT ${token}`,
//             },
//         });
//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }

//         // Accumulate results from this page
//         accumulatedResults = accumulatedResults.concat(data.results);

//         // If there's a next page, fetch the next page recursively
//         if (data.next) {
//             return fetchAllResults(data.next, accumulatedResults);
//         }

//         // Return the accumulated results along with other data fields
//         return {
//             count: data.count,
//             next: data.next,
//             previous: data.previous,
//             results: accumulatedResults,
//         };
//     };

//     const data = await fetchAllResults("/properties/area");
//     dispatch(getAreas(data));
//     return data;
// } catch (error) {
//     console.error('API Error:', error);
//     dispatch(setError(error.toString()));
// } finally {
//     dispatch(setLoading(false));
//   }
// };


// // Thunk to fetch contacts data
// export const fetchContacts = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));
//   const token = getState().auth.accessToken;

//   try {
//     const fetchAllResults = async (url, accumulatedResults = []) => {
//         // Check if the URL is a full URL or a relative path
//         const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

//         const response = await fetch(fullUrl, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `JWT ${token}`,
//             },
//         });
//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }

//         // Accumulate results from this page
//         accumulatedResults = accumulatedResults.concat(data.results);

//         // If there's a next page, fetch the next page recursively
//         if (data.next) {
//             return fetchAllResults(data.next, accumulatedResults);
//         }

//         // Return the accumulated results along with other data fields
//         return {
//             count: data.count,
//             next: data.next,
//             previous: data.previous,
//             results: accumulatedResults,
//         };
//     };

//     const data = await fetchAllResults("/properties/contacts");
    
//     dispatch(getContacts(data));
//     return data;
// } catch (error) {
//     console.error('API Error:', error);
//     dispatch(setError(error.toString()));
// } finally {
//     dispatch(setLoading(false));
//   }
// };

// // Thunk to fetch society data
// export const fetchSocieties = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));
//   const token = getState().auth.accessToken;
//   try {
//     const fetchAllResults = async (url, accumulatedResults = []) => {
//         // Check if the URL is a full URL or a relative path
//         const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

//         const response = await fetch(fullUrl, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `JWT ${token}`,
//             },
//         });
//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }

//         // Accumulate results from this page
//         accumulatedResults = accumulatedResults.concat(data.results);

//         // If there's a next page, fetch the next page recursively
//         if (data.next) {
//             return fetchAllResults(data.next, accumulatedResults);
//         }

//         // Return the accumulated results along with other data fields
//         return {
//             count: data.count,
//             next: data.next,
//             previous: data.previous,
//             results: accumulatedResults,
//         };
//     };

//     const data = await fetchAllResults("/properties/society");
//     dispatch(getSocieties(data));
//     return data;
// } catch (error) {
//     console.error('API Error:', error);
//     dispatch(setError(error.toString()));
// }
  
//   finally {
//     dispatch(setLoading(false));
//   }
// };
// // Thunk to fetch society data
// export const fetchSubUsers = () => async (dispatch, getState) => {
//   dispatch(setLoading(true));
//   const token = getState().auth.accessToken;
//   try {
//     const fetchAllResults = async (url, accumulatedResults = []) => {
//         // Check if the URL is a full URL or a relative path
//         const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

//         const response = await fetch(fullUrl, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `JWT ${token}`,
//             },
//         });
//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(response.statusText);
//         }

//         // Accumulate results from this page
//         accumulatedResults = accumulatedResults.concat(data.results);

//         // If there's a next page, fetch the next page recursively
//         if (data.next) {
//             return fetchAllResults(data.next, accumulatedResults);
//         }

//         // Return the accumulated results along with other data fields
//         return {
//             count: data.count,
//             next: data.next,
//             previous: data.previous,
//             results: accumulatedResults,
//         };
//     };

//     const data = await fetchAllResults("/properties/subuserlist");
//     dispatch(getSubusers(data));
//     return data;
// } catch (error) {
//     console.error('API Error:', error);
//     dispatch(setError(error.toString()));
// }
  
//   finally {
//     dispatch(setLoading(false));
//   }
// };

// export default namesSlice.reducer;



import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../util/Api';

const initialState = {
  property_types: null,
  property_subtypes: null,
  deal_types: null,
  bhk: null,
  furnitures: null,
  possessions: null,
  areas: null,
  cities: null,
  societies: null,
  contacts: null,
  subusers: null,
  loading: false,
  error: null,
};

export const namesSlice = createSlice({
  name: 'names',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setData: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    namesLogout: () => initialState,
  },
});

export const { setLoading, setError, setData, namesLogout } = namesSlice.actions;

// Utility function to fetch all paginated results
const fetchAllResults = async (url, token, accumulatedResults = []) => {
  const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;
  const response = await fetch(fullUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `JWT ${token}` : undefined,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
  }

  const data = await response.json();
  accumulatedResults = accumulatedResults.concat(data.results);

  return data.next ? fetchAllResults(data.next, token, accumulatedResults) : accumulatedResults;
};

// Generic thunk to fetch data and dispatch it
const fetchDataThunk = (endpoint, key) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;

  try {
    const data = await fetchAllResults(endpoint, token);
    dispatch(setData({ key, data }));
  } catch (error) {
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Specific thunks for each data type
export const fetchAllData = () => async (dispatch) => {
  const endpoints = [
    { url: '/properties/propertytype', key: 'property_types' },
    { url: '/properties/propertysubtype', key: 'property_subtypes' },
    { url: '/properties/deal_type', key: 'deal_types' },
    { url: '/properties/bhk', key: 'bhk' },
    { url: '/properties/furniture', key: 'furnitures' },
    { url: '/properties/possession', key: 'possessions' },
  ];

  dispatch(setLoading(true));
  try {
    await Promise.all(
      endpoints.map(({ url, key }) => dispatch(fetchDataThunk(url, key)))
    );
  } catch (error) {
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchCity = () => fetchDataThunk('/properties/city', 'cities');
export const fetchAreas = () => fetchDataThunk('/properties/area', 'areas');
export const fetchSocieties = () => fetchDataThunk('/properties/society', 'societies');
export const fetchContacts = () => fetchDataThunk('/properties/contacts', 'contacts');
export const fetchSubUsers = () => fetchDataThunk('/properties/subuserlist', 'subusers');

export default namesSlice.reducer;
